html,
body {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

ul {
    margin: 0;
}

.ReactTable {
    min-height: 240px;
}

